.error {
  @apply font-bold text-center;
  color: var(--mantine-color-red-7);
}

.warning {
  @apply font-bold text-center;
  color: var(--mantine-color-orange-7);
}

.success {
  @apply font-bold text-center;
  color: var(--mantine-color-green-7);
}

.loader {
  @apply block my-0 mx-auto mt-2;
}

.messageIcon {
  @apply mr-2;
}

.capitaliseFirstLetter {
  &::first-letter {
    @apply capitalize;
  }
}