.message {
  @apply flex;
  color: var(--mantine-color-green-9);

  span {
    margin-left: 1rem;
  }
}

.validateLink {
  @apply text-center mt-8 text-xs;
}